

















































































































































































import { useUiState, useUserOptins } from '~/composables';
import { SfButton, SfLink, SfSidebar, SfTabs } from '@storefront-ui/vue';
import { computed, defineComponent, onMounted, ref, useFetch, watch } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import ProfileUpdateForm from '~/modules/customer/pages/MyAccount/MyProfile/ProfileUpdateForm.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import userGetters from '~/modules/customer/getters/userGetters';
import UserAddressDetails from '~/components/UserAddressDetails';
import { cacheRole } from '~/helpers/customer/role';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'MyProfile',
  components: {
    SfTabs,
    SfLink,
    SvgImage,
    ProfileUpdateForm,
    SfSidebar,
    UserAddressDetails,
    SfButton,
  },

  setup() {
    const { togglePasswordSidebar } = useUiState();
    const { loading: loadingOptins, setOptins } = useUserOptins();
    const customerStore = useCustomerStore();
    const { passwordChanged } = storeToRefs(customerStore);

    const {
      loading,
      user,
      load,
    } = useUser();

    const checkedSms = ref(false);
    const checkedMail = ref(false);
    const userMyProfile = ref({});
    const confirmPreferencesMsg = ref('');
    const confirmPasswordChangeMsg = ref('');

    useFetch(async () => {
      await load({
        customQuery: { customer: 'customCustomer' }
      });
      userMyProfile.value = user.value;

      checkedSms.value = userMyProfile.value?.optins?.sms;
      checkedMail.value = userMyProfile.value?.optins?.email;
    });

    onMounted(() => {
      customerStore.passwordChanged = false;
    })

    watch(passwordChanged, () => {
      if (passwordChanged.value === true) {
        confirmPasswordChangeMsg.value = 'Vos changements ont bien été pris en compte.';
      }
    })

    const firstname = computed(() => userGetters.getFirstName(user.value));
    const lastname = computed(() => userGetters.getLastName(user.value));
    const mail = computed(() => userGetters.getEmailAddress(user.value));
    const createdAtUser = computed(() => userGetters.getCreatedAt(user.value));

    const { isSubAccount, isCommercial } = cacheRole();

    const setCustomerOptins = async () => {
      confirmPreferencesMsg.value = '';

      const res = await setOptins(checkedMail.value, checkedSms.value);

      if (res && res.data && res.data.updateContactPreference) {
        confirmPreferencesMsg.value = 'Vos changements ont bien été pris en compte.'
      }
    }

    function formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('FR').format(date);
    }

    return {
      loading,
      firstname,
      lastname,
      mail,
      createdAtUser,
      togglePasswordSidebar,
      checkedSms,
      checkedMail,
      setCustomerOptins,
      loadingOptins,
      userMyProfile,
      isSubAccount,
      isCommercial,
      formatDate,
      confirmPreferencesMsg,
      confirmPasswordChangeMsg
    };
  },
});
